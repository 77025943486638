import { createContext, useContext } from 'react'
import { renderURLSearchParams } from 'gen/fetch.pb'

export const SvgFolderContext = createContext('')

type CoinIDType =
  | {
      symbol: string
    }
  | {
      address: {
        address: string
        chain: string
      }
    }

export type GetPriceRequest = {
  timestamp: string
  coinId: CoinIDType
}

export const PriceFetcherContext = createContext(async function priceFetcher(params: GetPriceRequest) {
  const res = await fetch(`/api/v1/prices?${renderURLSearchParams(params, [])}`)
  if (!res.ok) {
    throw new Error('failed to fetch')
  }
  return (res as any).json()
})

type openContractFn = (address: string, chain: string) => void
export const OpenContractContext = createContext<openContractFn | undefined>(undefined)

type openCompilationFn = (id: string) => void
export const OpenCompilationContext = createContext<openCompilationFn | undefined>(undefined)

export const useDetectExtenstion = () => {
  const folderPath = useContext(SvgFolderContext)
  return Boolean(folderPath)
}
