import { createContext, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'

export const NavSizeContext = createContext({
  small: true,
  showLabel: true,
  setSmall: (small: boolean) => {
    // do nothing
  },
  setShowLabel: (showLabel: boolean) => {
    // do nothing
  }
})

const FLAG = 'y'

export const useNavSize = (defaultData: { small: boolean; showLabel: boolean; syncWithStorage?: string }) => {
  const [small, setSmall] = useState(defaultData.small)
  const [showLabel, setShowLabel] = useState(defaultData.showLabel)
  const { query } = useRouter()
  const isSmallNav = query?.nav === 's'

  useEffect(() => {
    if (defaultData.syncWithStorage) {
      if (isSmallNav) {
        setShowLabel(false)
        return
      }
      const stored = localStorage.getItem(defaultData.syncWithStorage)
      if (stored && stored === FLAG) {
        setShowLabel(!defaultData.showLabel)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData.syncWithStorage, isSmallNav])

  useEffect(() => {
    if (defaultData.syncWithStorage) {
      if (showLabel) {
        localStorage.removeItem(defaultData.syncWithStorage)
      } else {
        localStorage.setItem(defaultData.syncWithStorage, FLAG)
      }
    }
  }, [showLabel, defaultData.syncWithStorage])

  return useMemo(
    () => ({
      small,
      setSmall,
      showLabel,
      setShowLabel
    }),
    [small, showLabel]
  )
}
