import { atomWithHash } from 'jotai-location'
import { Router } from 'next/router'
import { atom, useAtom } from 'jotai'

interface Flight {
  name: string
  enable: { [key: string]: boolean }
}

export const DemoFlight: Flight = {
  name: 'demo',
  enable: {
    dev: true,
    '*': false
  }
}

export const OobFlight: Flight = {
  name: 'oob',
  enable: {
    dev: true,
    '*': false
  }
}

export const AutoProcessorFlight: Flight = {
  name: 'auto-processor',
  enable: {
    dev: true,
    'test.sentio.xyz': true,
    'staging.sentio.xyz': true,
    '*': false
  }
}

export const LogFlight: Flight = {
  name: 'log',
  enable: {
    dev: true,
    '*': true
  }
}

export const TableColumnsFlight: Flight = {
  name: 'tc',
  enable: {
    dev: true,
    '*': false
  }
}

export const OrgFlight: Flight = {
  name: 'org',
  enable: {
    dev: true,
    '*': true
  }
}

export const AnalyticsFlight: Flight = {
  name: 'analytics',
  enable: {
    dev: true,
    '*': false
  }
}

export const InsightsFlight: Flight = {
  name: 'insights',
  enable: {
    dev: true,
    '*': true
  }
}

export const NewEditFlight: Flight = {
  name: 'newedit',
  enable: {
    dev: true,
    '*': true
  }
}

export const ClickHouseFlight: Flight = {
  name: 'clickhouse',
  enable: {
    dev: true,
    '*': false
  }
}

export const NewLayoutFlight: Flight = {
  name: 'new-layout',
  enable: {
    dev: true,
    '*': true
  }
}

export const UsageFlight: Flight = {
  name: 'usage',
  enable: {
    dev: true,
    '*': true
  }
}

export const EventLogView: Flight = {
  name: 'event-log-view',
  enable: {
    dev: true,
    '*': true
  }
}

export const EventLogPanel: Flight = {
  name: 'event-log-panel',
  enable: {
    dev: true,
    '*': true
  }
}

export const SimulatorFlight: Flight = {
  name: 'simulator',
  enable: {
    dev: true,
    '*': true
  }
}

export const EmailAlertsFlight: Flight = {
  name: 'email-alerts',
  enable: {
    dev: true,
    '*': false
  }
}

export const ImportProjectFlight: Flight = {
  name: 'import-project',
  enable: {
    dev: true,
    '*': true
  }
}

export const DataExplorerFlight: Flight = {
  name: 'data-explorer',
  enable: {
    dev: true,
    '*': true
  }
}
export const GraphqlFlight: Flight = {
  name: 'graphql',
  enable: {
    dev: true,
    '*': true
  }
}

export const AiFlight: Flight = {
  name: 'ai',
  enable: {
    dev: true,
    '*': true
  }
}

export const AccountsFlight: Flight = {
  name: 'accounts',
  enable: {
    dev: true,
    '*': true
  }
}

export const SolidityIntelligenceFlight: Flight = {
  name: 'solidity-intelligence',
  enable: {
    dev: true,
    '*': true
  }
}

export const RetentionFlight: Flight = {
  name: 'retention',
  enable: {
    dev: true,
    '*': true
  }
}

export const CompilationFlight: Flight = {
  name: 'compilation',
  enable: {
    dev: true,
    '*': true
  }
}

export const SnapFlight: Flight = {
  name: 'snap',
  enable: {
    dev: true,
    '*': false
  }
}

export const ExtensionFlight: Flight = {
  name: 'extension',
  enable: {
    dev: true,
    '*': true
  }
}

export const CompareFlight: Flight = {
  name: 'compare',
  enable: {
    dev: true,
    '*': true
  }
}

export const BundleSimulateFlight: Flight = {
  name: 'bundle-simulate',
  enable: {
    dev: true,
    '*': true
  }
}

export const ContractStorageFlight: Flight = {
  name: 'contract-storage',
  enable: {
    dev: true,
    '*': true
  }
}

export const ContractOverrideFlight: Flight = {
  name: 'contract-override',
  enable: {
    dev: true,
    '*': true
  }
}

export const SuiFundflowFlight: Flight = {
  name: 'sui-fundflow',
  enable: {
    dev: true,
    '*': false
  }
}

const AllFlights = [
  DemoFlight,
  OobFlight,
  AutoProcessorFlight,
  LogFlight,
  TableColumnsFlight,
  OrgFlight,
  NewLayoutFlight,
  AnalyticsFlight,
  UsageFlight,
  InsightsFlight,
  EventLogView,
  EventLogPanel,
  SimulatorFlight,
  NewEditFlight,
  EmailAlertsFlight,
  ImportProjectFlight,
  DataExplorerFlight,
  AccountsFlight,
  AiFlight,
  GraphqlFlight,
  SolidityIntelligenceFlight,
  RetentionFlight,
  CompilationFlight,
  SnapFlight,
  ExtensionFlight,
  CompareFlight,
  BundleSimulateFlight,
  ContractStorageFlight,
  ContractOverrideFlight,
  SuiFundflowFlight
]

function getDefaultFlights() {
  const hostname = typeof window !== 'undefined' ? window.location.hostname : ''
  const isDev = hostname === 'localhost' || hostname.startsWith('dev') || hostname.startsWith('test')
  const flights = AllFlights.filter((f) => {
    return f.enable['dev'] && isDev ? true : f.enable[hostname] || f.enable['*']
  })
  return flights.map((f) => f.name).join(',')
}

const flightAtom = atomWithHash('flight', getDefaultFlights(), {
  serialize: (value) => value,
  deserialize: (value) => value, // || unstable_NO_STORAGE_VALUE,
  setHash: 'replaceState',
  subscribe: (callback) => {
    Router.events.on('routeChangeComplete', callback)
    window.addEventListener('hashchange', callback)
    return () => {
      Router.events.off('routeChangeComplete', callback)
      window.removeEventListener('hashchange', callback)
    }
  }
})

export const flightAtoms = atom((get) => get(flightAtom).split(','))

const flightMap: { [key: string]: Flight } = AllFlights.reduce((acc, flight) => {
  acc[flight.name] = flight
  return acc
}, {})

export function useFlight() {
  const [flight] = useAtom(flightAtom)
  const flights = flight.split(',')

  const flightEnabled = (f: Flight) => {
    if (!f?.name) return false
    return flights.includes(f.name)
  }

  const getFlights = () => {
    return flights.map((f) => flightMap[f]) as Flight[]
  }

  return {
    isFlightEnabled: flightEnabled,
    getFlights
  }
}

export function useFlightWith(initFlight: Flight) {
  const [flights] = useAtom(flightAtoms)
  return flights.includes(initFlight.name)
}
