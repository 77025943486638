import classNames from 'lib/classnames'
import { ReactElement } from 'react'

interface ButtonItem<T> {
  label: string | ReactElement
  value: T
  icon?: React.ReactNode
}

interface Props<T> {
  buttons: ButtonItem<T>[]
  value?: T
  onChange?: (key: T) => void
  small?: boolean
  flexGrow?: boolean
  theme?: 'light' | 'default'
  buttonClassName?: string
}

const ThemeColor = {
  light: 'text-primary bg-primary/10 border-primary hover:bg-primary/30 active:bg-primary/20',
  default: 'text-white bg-primary border-primary hover:bg-primary-500 active:bg-primary-700'
}

export default function ButtonGroup<T>({
  buttons,
  value,
  onChange,
  small,
  flexGrow,
  theme = 'default',
  buttonClassName
}: Props<T>) {
  const selectedIndex = buttons.findIndex((button) => button.value === value)
  return (
    <span className={classNames('relative z-0 rounded-md', flexGrow ? 'flex w-full' : 'inline-flex')}>
      {buttons.map((button, idx) => (
        <button
          key={idx}
          type="button"
          onClick={() => onChange && onChange(button.value)}
          className={classNames(
            'border-border-color relative inline-flex items-center border' +
              ' focus:ring-primary-500 focus:border-primary-500 font-medium focus:z-10 focus:outline-none focus:ring-1',
            idx === selectedIndex
              ? ThemeColor[theme]
              : 'dark:bg-sentio-gray-100 text-text-foreground bg-white hover:bg-gray-50',
            idx == 0 ? 'rounded-l-md' : idx == buttons.length - 1 ? 'rounded-r-md' : '',
            idx !== 0 && value !== button.value ? 'border-l-transparent' : '',
            selectedIndex - 1 === idx ? 'border-r-transparent' : '',
            small ? 'px-2.5 py-1.5 text-xs' : 'text-ilabel px-4 py-1.5',
            flexGrow ? 'basis-full justify-center whitespace-nowrap' : '',
            buttonClassName
          )}
          aria-pressed={idx === selectedIndex}
        >
          {button?.icon ?? null}
          {button.label}
        </button>
      ))}
    </span>
  )
}
