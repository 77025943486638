import { createContext } from 'react'

export const ProjectContext = createContext<{
  owner?: string
  slug?: string
}>({
  owner: '',
  slug: ''
})

export const DashModeContext = createContext(false)
