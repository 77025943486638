import classNames from 'lib/classnames'
import { PopoverButton } from 'components/common/popper/PopoverButton'
import { PopoverTooltip } from '../tooltip/PopoverTooltip'
import isString from 'lodash/isString'
import { CopyButton } from '../buttons/CopyButton'

interface Props {
  status: string
  className?: string
  colorClasses?: string
  roundClasses?: string
  error?: string | React.ReactNode
  bubble?: boolean // if contains a prefix bubble
  role?: string
  trigger?: 'click' | 'hover'
}

export const enum StatusRole {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Disabled = 'disabled',
  Info = 'info'
}

const VersionStateColors: { [key: string]: string } = {
  [StatusRole.Success]: 'bg-cyan-600/10 text-cyan-600',
  [StatusRole.Warning]: 'bg-orange-600/10 text-orange-600',
  [StatusRole.Error]: 'bg-red-600/10 text-red-600',
  [StatusRole.Disabled]: 'bg-gray-600/10 text-gray-600',
  [StatusRole.Info]: 'bg-gray-300/10 text-gray-300'
}

export function StatusBadge({
  status,
  className,
  colorClasses: _colorClasses,
  roundClasses,
  error,
  bubble,
  role,
  trigger = 'click'
}: Props) {
  const colorClasses = _colorClasses || VersionStateColors[role || StatusRole.Info]

  const element = (
    <span
      className={classNames(
        'text-ilabel inline-flex items-center px-2 py-0.5 font-medium',
        colorClasses,
        roundClasses ? roundClasses : 'rounded-full',
        className
      )}
      data-test-status={status}
    >
      {bubble && <div className="mr-1.5 h-1.5 w-1.5 rounded-full bg-current" />}
      {status}
    </span>
  )

  if (error) {
    const errorElement = isString(error) ? (
      <pre className="whitespace-pre-wrap break-words pb-6">
        <div className="dark:bg-sentio-gray-100 absolute bottom-1.5 right-2 h-5 w-5 rounded-full bg-white text-center shadow-sm hover:bg-gray-100">
          <CopyButton text={error} iconClass="text-gray w-3.5 h-3.5 mt-[3px]" />
        </div>
        {error}
      </pre>
    ) : (
      error
    )
    if (trigger === 'click') {
      return (
        <PopoverButton
          placement="bottom-start"
          content={
            <div
              className={classNames(
                'absolute z-10 mt-2 p-2',
                'text-icontent',
                'origin-top-right rounded-md',
                'dark:bg-sentio-gray-100 bg-white text-red-600 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-100'
              )}
            >
              <div className="max-h-60 w-max max-w-sm overflow-auto sm:max-w-lg lg:max-w-screen-md">{errorElement}</div>
            </div>
          }
        >
          {element}
        </PopoverButton>
      )
    } else if (trigger === 'hover') {
      return (
        <PopoverTooltip
          hideArrow
          text={
            <div className="max-h-60 w-max max-w-sm overflow-auto sm:max-w-lg lg:max-w-screen-md">{errorElement}</div>
          }
          offsetOptions={{
            mainAxis: 8,
            crossAxis: 0
          }}
          placementOption="bottom-start"
        >
          {element}
        </PopoverTooltip>
      )
    }
  }
  return element
}

export default StatusBadge
