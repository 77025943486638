import { useContext, useEffect, useMemo } from 'react'
import { WebService } from 'gen/service/web'
import useApi, { getKey } from './use-api'
import { useRouter } from 'next/router'
import { Permission, Project } from 'gen/service/common'
import withJsonApi from './with-json-api'
import { useSWRConfig } from 'swr'
import { useAuth0 } from '@auth0/auth0-react'
import { ProjectOwnerAndSlug } from '../../gen/service/web'
import { ProjectContext } from '../dash/context'

export function useProject(requirePermission?: Permission[]) {
  const router = useRouter()
  const { mutate: mutateAll } = useSWRConfig()
  const projectContext = useContext(ProjectContext)
  const { owner: ownerInUrl, slug: slugInUrl } = router.query
  const { owner, slug } = projectContext?.owner ? projectContext : { owner: ownerInUrl, slug: slugInUrl }
  const { isLoading: isAuth0Loading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  const req = useMemo(() => {
    if (owner && slug && !isAuth0Loading) {
      return {
        ownerName: owner as string,
        slug: slug as string
      }
    }
    return null
  }, [owner, slug, isAuth0Loading])

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
    }
  }, [getAccessTokenSilently, isAuthenticated])
  const { data, loading, mutate, token, error, ready } = useApi(WebService.GetProject, req)

  if (error) {
    switch (error.status) {
      case 401:
      case 403:
        if (!isAuthenticated) {
          loginWithRedirect({ appState: { returnTo: window.location.href } })
        } else {
          throw error
        }
        break
      case 404:
        throw error
      default:
        break
    }
  }

  const project = data?.project
  const allowRead = (data?.permissions || []).includes(Permission.READ)
  const allowEdit = (data?.permissions || []).includes(Permission.WRITE)
  const allowAdmin = (data?.permissions || []).includes(Permission.ADMIN)

  if (requirePermission && project) {
    for (const p of requirePermission) {
      if (!(data?.permissions || []).includes(p)) {
        throw { status: 403, message: 'Insufficient permission to access current page' }
      }
    }
  }

  async function addProjectMember(usernameOrEmail: string) {
    const response = await withJsonApi(
      WebService.AddProjectMember,
      token
    )(
      project && {
        projectId: project.id,
        usernameOrEmail
      }
    )
    if (project) {
      project.members = response.members
      mutate({
        projects: [project]
      })
    }
  }

  async function removeProjectMember(usernameOrEmail: string) {
    const response = await withJsonApi(
      WebService.RemoveProjectMember,
      token
    )(
      project && {
        projectId: project.id,
        usernameOrEmail
      }
    )
    if (project) {
      project.members = response.members
    }
  }

  async function deleteProject() {
    await withJsonApi(
      WebService.DeleteProject,
      token
    )(
      project && {
        projectId: project.id
      }
    )
    mutateAll(getKey(WebService.GetProject))
  }

  return {
    project,
    allowRead,
    allowEdit,
    allowAdmin,
    loading,
    mutate,
    addProjectMember,
    removeProjectMember,
    error,
    deleteProject,
    ready,
    owner,
    slug
  }
}

export function getProjectUrl(p?: Project) {
  if (!p) {
    return ''
  }
  if (p.owner?.user?.username) {
    return `${p.owner.user.username}/${p.slug}`
  }
  if (p.owner?.organization?.name) {
    return `${p.owner.organization.name}/${p.slug}`
  }
  return `${p.slug}`
}

export function useImportProjects() {
  const router = useRouter()
  const { project } = useProject()
  const req = project
    ? {
        ownerName: project?.ownerName,
        slug: project?.slug
      }
    : null
  const { data, loading, mutate, error, ready } = useApi(WebService.GetImportedProject, req)

  async function importProject(name: string, importProjects: ProjectOwnerAndSlug[]) {
    const response = await withJsonApi(WebService.ImportProject)({
      ownerName: project?.ownerName,
      slug: project?.slug,
      name,
      importProjects
    })
    mutate(response)
  }

  async function unImportProject(ownerName?: string, slugName?: string) {
    const response = await withJsonApi(WebService.UnImportProject)({
      ownerName: project?.ownerName,
      slug: project?.slug,
      unimportOwner: ownerName,
      unimportSlug: slugName
    })
    mutate(response)
  }

  return {
    importedProjects: data?.imports || [],
    loading,
    mutate,
    error,
    ready,
    importProject,
    unImportProject
  }
}
